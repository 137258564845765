import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Alert, Jumbotron } from 'reactstrap';
import fakeAuth from '../../../fakeAuth';
import Coaches from './partials/coaches';
import Players from './partials/players';
import Network from './partials/network';
import Invite from './partials/invite';
import { Animated } from 'react-animated-css';
import RedishHeader from '../../common/things/RedishHeader';
import PlayerDetails from '../../common/modals/PlayerDetails';
import Jerseys from '../../common/modals/Jerseys';
import CreateUser from '../../common/modals/CreateUser';
import Game from './partials/game';
import Event from './partials/event';
import AddGuestPlayer from './AddGuestPlayer';
import { Link } from 'react-router';
import request from 'superagent';
import config from '../../../config';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import AddGame from './partials/AddGame';
import AddPractice from './partials/AddPractice';
import AddEvent from './partials/AddEvent';
import { generateRandomId } from '../../../helpers';
import Attendance from './partials/Attendance';
import Uniforms from './partials/Uniforms';
import TeamEvaluations from '../../common/modals/TeamEvaluations';
import PlayerEvaluations from './partials/PlayerEvaluations';
import PremiumModal from '../../sqwad/modals/family';
import TeamPremiumModal from '../../sqwad/modals/premium';


class RosterTeam extends React.Component {

    state = {
        bootstrapped: false,
        adding: 0,
        mode: 0,
        roster: true,
        schedule: false,
        games_tab: 1
    }

    // Lifecycle
    componentWillMount = () => {
        const { params, setTitle, teamActions, seasons, fetchCarriers } = this.props, { idTeam, idSeason } = params;
        let season = _.find((seasons || []), s => parseInt(s.IdSeason, 10) === parseInt(idSeason, 10));

        // I don't know if this is working anymore on the current UI layout
        setTitle && setTitle({
            main: 'Roster',
            sub: season ? (season.Name || season.SeasonName) : ''
        });
        teamActions && teamActions.fetchTeam(idTeam);
        teamActions && teamActions.fetchTeamSeasons(idTeam);
        teamActions && teamActions.fetchSubscriptions(idTeam);
        teamActions && teamActions.fetchTeamEvaluations(idTeam);
        teamActions && teamActions.fetchRights(idTeam);
        teamActions && teamActions.fetch_questionaries();
        teamActions && teamActions.fetchOpponents();
        teamActions && teamActions.fetchLocations();
        teamActions && teamActions.fetch_topics(1);
        teamActions && teamActions.fetch_custom_attributes_templates();
        this.props.seasonActions && this.props.seasonActions.fetchDisclaimer(1);
        fetchCarriers && fetchCarriers();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.seasons && nextProps.selectedTeam && nextProps.rights && !this.state.bootstrapped &&
            nextProps.customAttributesTemplates
        ) {
            this.setState({
                team: {
                    ...nextProps.selectedTeam,
                    rights: nextProps.rights
                },
                team_subscriptions: nextProps.team_subscriptions,
                season: _.find(nextProps.seasons, s => parseInt(s.IdSeason, 10) === parseInt(this.props.params.idSeason, 10)),
                bootstrapped: true
            }, () => {
                const { rosterActions, teamActions, setTitle } = this.props, { season = {} } = this.state;
                setTitle && setTitle({
                    main: 'Roster',
                    sub: season.Name || season.SeasonName
                });
                rosterActions && rosterActions.fetchRoster(fakeAuth.getUser().IdUser, season.IdTeamDivision);
                teamActions && teamActions.fetchSchedule(season.IdTeamDivision);
                teamActions && teamActions.fetchOtherEvents(season.IdTeamDivision);
                rosterActions && rosterActions.fetchNetwork(season.IdTeamDivision);
                rosterActions && rosterActions.fetchNetworkCoaches(season.IdTeamDivision);
            });
        }
    }
    componentWillUnmount = () => {
        const { rosterActions } = this.props;
        rosterActions.clearRoster();
    }
    //

    setup = () => {
        if (
            this.props.selectedTeam &&
            this.props.permissions &&
            this.state.season && !this.state.bootstrapped
        ) {
            this.setState({ bootstrapped: true });
        }
    }

    toggleAdding = (adding) => {
        const { limits } = this.props;
        if (adding === 3 && !limits.CanEditRoster_Allowed) { alert(limits.Reason); return; }
        this.setState({ adding, mode: adding ? this.state.mode : 0, adding_roster: adding ? true : false });
    };

    onMode = mode => this.setState({ mode });
    onDeleteEvent = IdEvent => {
        // Fetch games with filters applied
        const { season } = this.state;
        request.delete(`${config.apiEndpoint}/api/v4/family/event/${IdEvent}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => {
                this.setState({ deleting_event: false, non_official_event: null, official_game: null, entity: null });
                this.props.teamActions.fetchSchedule(season.IdTeamDivision);
                this.props.teamActions.fetchOtherEvents(season.IdTeamDivision);
            });
    }

    deselectPlayer = () => this.setState({ selectedPlayer: null });

    selectPlayer = player => this.setState({ selectedPlayer: player });

    refresh = () => {
        const { rosterActions } = this.props, { season } = this.state;
        rosterActions && rosterActions.fetchRoster(fakeAuth.getUser().IdUser, season.IdTeamDivision);
    }

    update = () => {
        this.deselectPlayer();
        this.refresh();
    }

    download = () => {

        const { downloading, season } = this.state;
        if (downloading) return;

        this.setState({
            downloading: true
        }, () => {
            // Fetch games with filters applied
            request.post(`${config.apiEndpoint}/api/v4/roster/travel/download/${season.IdTeamDivision}`)
                .send({})
                .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                .responseType('arraybuffer')
                .then((data) => {
                    let file = new Blob([data.body], { type: 'application/pdf' });
                    require("downloadjs")(file, 'TRAVEL_ROSTER.pdf');
                    this.setState({ downloading: false, printing_roster: false });
                });
        });
    }

    onJerseys = () => this.setState({ jerseing: !this.state.jerseing });

    render() {

        const { season, bootstrapped, adding, mode, selectedPlayer, jerseing, evaluating, selectedFamilyMember, error, schedule } = this.state,
            { roster, permissions = {}, roles, limits, params, selectedTeam, carriers, division } = this.props,
            { idTeam, idSeason } = params;
        const { games_tab, non_official_event, official_game } = this.state;

        let coaches = _.chain(roster || []).filter(u => u.IdRole === 4).value(),
            players = _.chain(roster || []).filter(u => u.IdRole === 3 || u.IdGuestPlayer).value();

        const { CanEditRoster_Allowed, CanEditRoster_Reason } = permissions || {};

        return (
            <section>

                <div style={{ position: 'fixed', zIndex: 1, right: 50 }} className="w3-content">
                    <Alert color="danger" isOpen={error ? true : false} toggle={() => { this.setState({ error: null }) }}>
                        {this.state.error}
                    </Alert>
                </div>

                {/* LISTS */}
                {bootstrapped && season && <div className="w3-container mt-4">

                    {/* TABS */}
                    <Animated animationIn="slideInRight" animationInDuration={200} animationOutDuration={200} animationOut="slideOutRight" isVisible={!adding && !selectedPlayer && !jerseing && !selectedFamilyMember && !evaluating} className="w3-content d-flex flex-row montserrat">

                        <div className="d-flex flex-row card shadow w-100 p-2">
                            <Link to={`/roster/${idTeam}`} className="align-self-center d-flex flex-row pr-4" style={{ zIndex: 10 }}>
                                <i className="fas fa-arrow-left font-16 black ml-3 align-self-center" />
                                <div className="align-self-center ml-4" style={{ width: 100, height: 60, background: `url(${season.seasonImage || season.LeagueImage}) no-repeat center center`, backgroundSize: 'contain' }} />
                                <span className="ml-4 black align-self-center font-16 line1">{season && (season.SeasonName || season.Name)}</span>
                            </Link>
                            <Link to={`/roster/wizard/${idTeam}/${idSeason}`} className="align-self-center font-12 blue ml-auto mr-2">Help?</Link>
                        </div>
                        <button onClick={() => this.setState({ roster: true, schedule: false })} className={`${this.state.roster ? 'btn-info' : ''} card shadow p-2 d-flex flex-column justify-content-center`} style={{ width: 100 }}>
                            <i className="icon-users font-24 align-self-center" />
                            <span className="font-10 align-self-center">Roster</span>
                        </button>
                        <button onClick={() => this.setState({ roster: false, schedule: true, games_tab: (!this.props.games.length && this.props.events.length) ? 2 : 1 })} className={`${this.state.schedule ? 'btn-info' : ''} card shadow p-2 d-flex flex-column justify-content-center`} style={{ width: 100 }}>
                            <i className="icon-calendar font-20 align-self-center" />
                            <span className="font-10 align-self-center">Schedule</span>
                        </button>
                        {/*<button onClick={() => this.setState({ roster: false, schedule: true, games_tab: (!this.props.games.length && this.props.events.length) ? 2 : 1 })} className={`${this.state.schedule ? 'btn-info' : ''} card shadow p-2 d-flex flex-column justify-content-center`} style={{ width: 100 }}>
                            <i className="icon-calendar font-20 align-self-center" />
                            <span className="font-8 align-self-center">Lineups<br />Depth Charts</span>
                        </button>*/}

                    </Animated>

                    {/* ROSTER */}
                    {this.state.roster && <div className="w3-content card shadow mt-2">

                        {/* COACHES */}
                        {(!adding || adding === 4) && <section>
                            <div className="d-flex flex-row montserrat p-3">
                                <span className="font-14 toBold black ml-4 align-self-center">Coaches / Admins ({coaches.length})</span>
                                <span onClick={() => this.setState({ printing_roster: true })} className="font-14 pointer ml-auto blue underlined align-self-center">Print Roster</span>
                            </div>
                            <Coaches {...this.props} coaches={coaches} onClick={this.selectPlayer} />
                        </section>}

                        {/* PLAYERS */}
                        {!this.state.adding_roster && <hr className="m-4" />}
                        {(!adding || adding === 3) && <section>
                            <div className="d-flex flex-row montserrat p-3">
                                <span className="font-14 toBold black ml-4 align-self-center">Players ({_.filter(players, p => !p.IdGuestPlayer).length}{limits && limits.Roster_Max ? ` / ${limits.Roster_Max}` : ''})</span>
                            </div>
                            <Players {...this.props} players={_.filter(players, p => !p.IdGuestPlayer)} onClick={this.selectPlayer} />
                            <div className="d-flex flex-row montserrat p-3">
                                <span className="font-14 toBold black ml-4 align-self-center">Guests ({_.filter(players, p => p.IdGuestPlayer).length})</span>
                            </div>
                            <Players {...this.props} players={_.filter(players, p => p.IdGuestPlayer)} onClick={this.selectPlayer} />
                        </section>}

                        <div style={{ height: 100 }} />
                    </div>}

                    {/* SCHEDULE */}
                    {this.state.schedule && <div className="w3-content">

                        {/* TABS */}
                        <div className="d-flex flex-row py-2">
                            <button onClick={() => this.setState({ games_tab: 1 })} className={`btn ${games_tab === 1 ? 'btn-info' : 'btn-link bg-white'}`}>GAMES ({(this.props.games || []).length})</button>
                            <button onClick={() => this.setState({ games_tab: 2 })} className={`btn ${games_tab === 2 ? 'btn-info' : 'btn-link bg-white'} ml-1`}>OTHER EVENTS ({(this.props.events || []).length})</button>
                            {games_tab === 1 && <ReactHTMLTableToExcel id="test-table-xls-button" className="ml-auto align-self-center download-table-xls-button btn btn-sm btn-warning" table="table-to-xls" filename={`Schedule - ${season.SeasonName || season.Name}`}
                                sheet={`Schedule - ${season.SeasonName || season.Name}`} buttonText="Export as XLS" />}
                        </div>

                        {/* OFFICIAL GAMES */}
                        {games_tab === 1 && this.props.games && _.filter(this.props.games, e => moment(e.DateTimeStart_Date_Format, 'MM/DD/YYYY').isAfter(moment())).length > 0 && <h3 className="montserrat text-center m-2">Upcoming</h3>}
                        {games_tab === 1 && this.props.games && this.props.games.length > 0 && _.filter(this.props.games, e => moment(e.DateTimeStart_Date_Format, 'MM/DD/YYYY').isAfter(moment())).map((game, i) => <Game key={i} game={game} onClick={() => this.setState({ official_game: game })} />)}
                        {games_tab === 1 && this.props.games && _.reject(this.props.games, e => moment(e.DateTimeStart_Date_Format, 'MM/DD/YYYY').isAfter(moment())).length > 0 && <h3 className="montserrat text-center m-2">Past Games</h3>}
                        {games_tab === 1 && this.props.games && this.props.games.length > 0 && _.reject(this.props.games, e => moment(e.DateTimeStart_Date_Format, 'MM/DD/YYYY').isAfter(moment())).map((game, i) => <Game key={i} game={game} onClick={() => this.setState({ official_game: game })} />)}

                        {/* NO GAMES */}
                        {games_tab === 1 && this.props.games && this.props.games.length === 0 && <Jumbotron>
                            <h1>Hmm.!</h1>
                            <h2>Seems like there are no games scheduled for this team yet.</h2>
                        </Jumbotron>}

                        {/* NON_OFFICIAL EVENTS */}
                        {games_tab === 2 && this.props.events && _.filter(this.props.events, e => moment(e.DateTimeStart_Date_Format, 'MM/DD/YYYY').isAfter(moment())).length > 0 && <h3 className="montserrat text-center m-2">Upcoming</h3>}
                        {games_tab === 2 && this.props.events && _.filter(this.props.events, e => moment(e.DateTimeStart_Date_Format, 'MM/DD/YYYY').isAfter(moment())).map((event, i) => <Event event={event} key={generateRandomId(5)} onClick={() => this.setState({ non_official_event: event })} />)}
                        {games_tab === 2 && this.props.events && _.reject(this.props.events, e => moment(e.DateTimeStart_Date_Format, 'MM/DD/YYYY').isAfter(moment())).length > 0 && <h3 className="montserrat text-center m-2">Past Events</h3>}
                        {games_tab === 2 && this.props.events && _.reject(this.props.events, e => moment(e.DateTimeStart_Date_Format, 'MM/DD/YYYY').isAfter(moment())).map((event, i) => <Event event={event} key={generateRandomId(5)} onClick={() => this.setState({ non_official_event: event })} />)}

                        {/* EXCEL TABLE */}
                        <table id="table-to-xls" className="table table-sm font-10 hide">
                            <thead>
                                <tr>
                                    <th>Game ID</th>
                                    <th>Game Date</th>
                                    <th>Home Team</th>
                                    <th>Away Team</th>
                                    <th>Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.games.map((game, i) => (
                                    <tr key={i}>
                                        <td>{game.IdGame || game.IdEvent}</td>
                                        <td>{moment(game.DateTimeStart_Date_Format, 'MM/DD/YYYY').format('MMM Do YYYY')} @ {`${game.DateTimeStart_Time_Format} ${game.TimeZoneAbbreviation}`}</td>
                                        <td>{game.home.TeamName}</td>
                                        <td>{game.away.TeamName}</td>
                                        <td>{game.LocationName}</td>
                                    </tr>)
                                )}
                            </tbody>
                        </table>

                        {/* NO EVENTS */}
                        {games_tab === 2 && this.props.events && this.props.events.length === 0 && <Jumbotron>
                            <h1>Hmm.!</h1>
                            <h2>Seems like there are no other events scheduled for this team yet.</h2>
                        </Jumbotron>}

                        <div style={{ height: 100 }} />
                    </div>}

                    {/* PLAYER EVALUATIONS */}
                    {this.state.evaluations && <Animated animationInDuration={200} animationOutDuration={200} animationOut="slideOutLeft" animationIn='slideInLeft' style={{ position: 'fixed', top: '6em', bottom: 0, left: 0, right: 0 }} className='bg-whiteish'>

                        <PlayerEvaluations {...this.props}
                            player={this.state.evaluations}
                            team={selectedTeam}
                            season={season}
                            toggle={() => this.setState({ evaluations: null })} />

                        {/* BACK ARROW */}
                        <div onClick={() => this.setState({ evaluations: null })} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="w3-gray d-flex flex-column justify-content-center">
                            <i className="fas fa-arrow-left black font-30 align-self-center" />
                        </div>
                    </Animated>}
                </div>}

                {/* SIDE MENU ROSTER */}
                <Animated animationIn='slideInRight' animationOut="slideOutRight"
                    animateOnMount={false}
                    isVisible={(bootstrapped && !schedule && !this.state.evaluations && season) ? true : false}
                    style={{ position: 'fixed', top: 250, right: 15 }}
                    className="d-flex flex-column"
                    animationInDuration={200}
                    animationOutDuration={200}>

                    {/* Administrative tools */}
                    {season && season.IsTeamDivisionAdmin === 1 && <div className="d-flex flex-column">
                        <button onClick={() => this.toggleAdding(3)} className={`${this.state.schedule ? '' : ''} hoverable card shadow p-2 d-flex flex-column justify-content-center`} style={{ width: 100 }}>
                            <i className="icon-sportsman font-20 align-self-center" />
                            <span className="font-10 align-self-center">Add Players</span>
                        </button>

                        <button onClick={() => this.toggleAdding(4)} className={`${this.state.schedule ? '' : ''} hoverable mt-1 card shadow p-2 d-flex flex-column justify-content-center`} style={{ width: 100 }}>
                            <i className="icon-pito font-20 align-self-center" />
                            <span className="font-10 align-self-center">Add Coaches</span>
                        </button>

                        <button onClick={this.onJerseys} className={`${this.state.schedule ? '' : ''} hoverable mt-1 card shadow p-2 d-flex flex-column justify-content-center`} style={{ width: 100 }}>
                            <i className="icon-jersey_outline font-20 align-self-center" />
                            <span className="font-10 align-self-center">Jerseys</span>
                        </button>

                        {season && (season.AllowGuestPlayersAtPoolplay || season.AllowGuestPlayersAtPlayoff) && <button onClick={() => this.setState({ adding_guests: true })} className={`${this.state.schedule ? '' : ''} hoverable mt-1 card shadow p-2 d-flex flex-column justify-content-center`} style={{ width: 100 }}>
                            <i className="fas fa-bookmark red font-20 align-self-center" />
                            <span className="font-10 align-self-center">+ Guests</span>
                        </button>}
                    </div>}

                    <button onClick={() => {
                        if (_.find(this.props.team_subscriptions, s => !s.Expired)) {
                            if (this.state.team.IsTeamAdmin || this.state.team.IsTeamOwner) {
                                this.setState({ evaluating: !this.state.evaluating, only_family: false });
                            } else {
                                // HERE!
                                this.setState({ evaluating: !this.state.evaluating, only_family: true });
                                /*setTimeout(() => this.setState({ evaluations: _.find(players, p => p.IdUser === fakeAuth.getUser().IdUser) }), 300);*/
                            }
                        } else this.setState({ upgrading: true });
                    }} className={`${_.find(this.props.team_subscriptions, s => !s.Expired) ? '' : ''} hoverable mt-1 card shadow p-2 d-flex flex-column justify-content-center`} style={{ width: 100 }}>
                        <i className="icon-bar-chart font-20 align-self-center" />
                        <span className="font-10 align-self-center">Evaluations</span>
                    </button>

                </Animated>

                {/* NON_OFFICIAL GAME */}
                {non_official_event && <div onClick={() => this.setState({ non_official_event: null })} className="Overlay show" />}
                <Animated animationIn='slideInRight' animationOut="slideOutRight" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={(non_official_event) ? true : false} style={{ position: 'fixed', right: 0, top: 0, zIndex: 10000, bottom: 0, width: 400, overflowY: 'auto' }} className="d-flex flex-column bg-whiteish">
                    <section className="d-flex flex-row">
                        {non_official_event && <h4 className="mx-4 montserrat black mt-2">{non_official_event.IdEventType === 4 ? 'TRAINING' : non_official_event.Name}</h4>}
                        {/* EDIT BUTTON */}
                        {this.state.team && (this.state.team.IsTeamAdmin || this.state.team.IsTeamOwner) && <button className="btn btn-link ml-auto underlined blue" onClick={() => {
                            switch (non_official_event.IdEventType) {
                                case 4: this.setState({ entity: non_official_event, adding_event: true, adding_practice: true }); break;
                                case 6: this.setState({ entity: non_official_event, adding_event: true, adding_another_event: true }); break;
                                default: break;
                            }
                        }}>Edit</button>}
                    </section>
                    {non_official_event && <div className="tile card p-4 mt-2 mx-2 d-flex flex-column">
                        <span className="line1 montserrat font-14 black align-self-center">{moment(non_official_event.DateTimeStart_Date_Format, 'MM/DD/YYYY').format('ddd, MMM Do YYYY')}</span>
                        <span className="line1 montserrat font-18 black align-self-center">{`${non_official_event.DateTimeStart_Time_Format} - ${non_official_event.DateTimeEnd_Time_Format}`}</span>
                        <span className="line1 montserrat font-12 black align-self-center">{`ID: ${non_official_event.IdEvent || non_official_event.IdGame}`}</span>
                    </div>}
                    {non_official_event && <div className="tile card p-4 mt-2 mx-2 d-flex flex-row">
                        <i className="fas fa-map-marker green font-40" />
                        <div className="d-flex flex-column ml-3">
                            <span className="line1 montserrat font-14 black">{non_official_event.LocationName}</span>
                            <span className="line1 montserrat font-12 black">{non_official_event.AddressLine1}</span>
                            <span className="line1 montserrat font-12 black">{`${non_official_event.City} ${non_official_event.StateCode} - ${non_official_event.ZipCode}`}</span>
                        </div>
                    </div>}
                    {non_official_event && <div className="tile card p-4 mt-2 mx-2 d-flex flex-column">
                        <span className="montserrat-bold font-12 black">Notes</span>
                        <span className="montserrat font-12 black" dangerouslySetInnerHTML={{ __html: non_official_event.Notes }}></span>
                    </div>}
                    {non_official_event && <div className="p-4 bg-gray d-flex flex-row justify-content-center">
                        <span className="align-self-center black montserrat">{`Arrive ${non_official_event.MinutesEarly ? `${non_official_event.MinutesEarly} minutes early` : 'on time'}`}</span>
                    </div>}

                    {/* UNIFORMS */}
                    {non_official_event && <Uniforms {...this.props} event={non_official_event} team={this.state.team} />}

                    {/* ATTENDANCE */}
                    {non_official_event && <Attendance {...this.props} event={non_official_event} />}

                    {this.state.team && (this.state.team.IsTeamAdmin || this.state.team.IsTeamOwner) && !this.state.deleting_event && <button onClick={() => this.setState({ deleting_event: true })} className="btn btn-link red mt-2 mb-4">DELETE EVENT</button>}
                    {this.state.deleting_event && <section className="d-flex flex-column">
                        <button onClick={() => this.onDeleteEvent(non_official_event.IdEvent)} className="btn btn-danger mt-2 align-self-center">CONFIRM DELETE</button>
                        <button onClick={() => this.setState({ deleting_event: false })} className="btn btn-link black mb-4 align-self-center">Cancel</button>
                    </section>}
                </Animated>

                {/* OFFICIAL GAME */}
                {official_game && <div onClick={() => this.setState({ official_game: null })} className="Overlay show" />}
                <Animated animationIn='slideInRight' animationOut="slideOutRight" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={(official_game) ? true : false} style={{ position: 'fixed', right: 0, top: 0, zIndex: 10000, bottom: 0, width: 400, overflowY: 'auto' }} className="d-flex flex-column bg-whiteish">
                    {official_game && <h4 className="mx-4 text-center montserrat black mt-2">{official_game.IdEventType === 4 ? 'GAME' : 'GAME'}</h4>}
                    {official_game && <div className="tile card p-4 mt-2 mx-2 d-flex flex-column">
                        <span className="line1 montserrat font-14 black align-self-center">{moment(official_game.DateTimeEnd_Date_Format, 'MM/DD/YYYY').format('ddd, MMM Do YYYY')}</span>
                        <span className="line1 montserrat font-18 black align-self-center">{`${official_game.DateTimeStart_Time_Format} - ${official_game.DateTimeStart_Time_Format}`}</span>
                        <span className="line1 montserrat font-12 black align-self-center">{`ID: ${official_game.IdEvent || official_game.IdGame}`}</span>
                    </div>}
                    {official_game && <div className="tile card p-4 mt-2 mx-2 d-flex flex-row">
                        <div className="w-100 p-3 d-flex flex-column justify-content-start">
                            <span className="font-10 jeff_blue align-self-center">Home</span>
                            <img className='align-self-center my-2 ' src={`${official_game.home.TeamImageUrl}?full=80`} style={{ width: 100 }} alt="" />
                            <span className="font-12 jeff_blue toBold align-self-center">{official_game.home.TeamName}</span>
                        </div>
                        <div className="w-25 d-flex flex-column justify-content-center">
                            <span className="font-20 black montserrat-bold">VS</span>
                        </div>
                        <div className="w-100 p-3 d-flex flex-column justify-content-start">
                            <span className="font-10 jeff_blue align-self-center">Away</span>
                            <img className='align-self-center my-2 ' src={`${official_game.away.TeamImageUrl}?full=80`} style={{ width: 100 }} alt="" />
                            <span className="font-12 jeff_blue toBold align-self-center">{official_game.away.TeamName}</span>
                        </div>
                    </div>}
                    {official_game && <div className="tile card p-4 mt-2 mx-2 d-flex flex-row">
                        <i className="fas fa-map-marker green font-40" />
                        <div className="d-flex flex-column ml-3">
                            <span className="line1 montserrat font-14 black">{official_game.LocationName}</span>
                            <span className="line1 montserrat font-12 black">{official_game.AddressLine1}</span>
                            <span className="line1 montserrat font-12 black">{`${official_game.City} ${official_game.StateCode} - ${official_game.ZipCode}`}</span>
                        </div>
                    </div>}
                    {official_game && <div className="tile card p-4 mt-2 mx-2 d-flex flex-column">
                        <span className="montserrat-bold font-12 black">Notes</span>
                        <span className="montserrat font-12 black" dangerouslySetInnerHTML={{ __html: official_game.Notes }}></span>
                    </div>}
                    {official_game && <div className="p-4 bg-gray d-flex flex-row justify-content-center">
                        <span className="align-self-center black montserrat">{`Arrive ${official_game.MinutesEarly} minutes early`}</span>
                    </div>}

                    {/* UNIFORMS */}
                    {official_game && <Uniforms {...this.props} event={official_game} team={this.state.team} />}

                    {/* ATTENDANCE */}
                    {official_game && <Attendance {...this.props} event={official_game} />}

                    {this.state.team && (this.state.team.IsTeamAdmin || this.state.team.IsTeamOwner) && !this.state.deleting_event && official_game && !official_game.IsOfficial && <button onClick={() => this.setState({ deleting_event: true })} className="btn btn-link red mt-2 mb-4">DELETE EVENT</button>}
                    {this.state.deleting_event && <section className="d-flex flex-column">
                        <button onClick={() => this.onDeleteEvent(official_game.IdEvent)} className="btn btn-danger mt-2 align-self-center">CONFIRM DELETE</button>
                        <button onClick={() => this.setState({ deleting_event: false })} className="btn btn-link black mb-4 align-self-center">Cancel</button>
                    </section>}
                </Animated>

                {/* INVITE TO ROSTER */}
                <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn='slideInRight' animationOut='slideOutRight' isVisible={(bootstrapped && division && this.state.adding_roster) ? true : false} style={{ position: 'fixed', right: 0, top: 0, bottom: 0, width: 350, overflowY: 'auto', borderLeft: '1px solid black' }} className="ease bg-white">
                    <div className="d-flex flex-row p-3">
                        {mode !== 0 && <i className="fas fa-arrow-left font-16 pointer" onClick={() => this.onMode(0)} />}
                        <span className="blue montserrat font-12 ml-auto">ADDING {adding === 3 ? 'PLAYERS' : 'COACHES'}</span>
                        <i className="fas fa-times font-16 pointer ml-auto" onClick={() => this.toggleAdding(0)} />
                    </div>
                    {mode === 0 && <div className="d-flex flex-column h-100 justify-content-center p-4">
                        <div onClick={() => this.onMode(2)} style={{ height: 'auto', border: '1px solid gray' }} className="align-self-center w-100 bg-gray d-flex flex-column justify-content-center p-4 montserrat pointer hoverable">
                            <span className="icon-mail font-24 black align-self-center" />
                            <span className="font-16 toBold black align-self-center">ADD {adding === 3 ? 'PLAYER' : 'COACH'}</span>
                            <span className="font-10 black line1 align-self-center">using their Email</span>
                        </div>
                        <div onClick={() => this.onMode(1)} style={{ height: 'auto', border: '1px solid gray' }} className="mt-4 align-self-center w-100 bg-gray d-flex flex-column justify-content-center p-4 montserrat pointer hoverable">
                            <span className="icon-coach_registration font-24 black align-self-center" />
                            <span className="font-16 toBold black align-self-center">ADD {adding === 3 ? 'PLAYER' : 'COACH'}</span>
                            <span className="font-10 black line1 align-self-center">from an Existing Roster</span>
                        </div>
                    </div>}
                    {mode === 1 && <Network {...this.props} roles={roles} season={season} roster={adding === 3 ? players : coaches} adding={adding} onSave={() => this.toggleAdding(0)} />}
                    {mode === 2 && <Invite {...this.props} season={season} roles={roles} roster={adding === 3 ? players : coaches} adding={adding} onSave={() => this.toggleAdding(0)} />}
                </Animated>

                {/* PRINT OPTIONS */}
                <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn='slideInRight' animationOut='slideOutRight' isVisible={(this.state.printing_roster) ? true : false} style={{ position: 'fixed', right: 0, top: 0, bottom: 0, width: 350, overflowY: 'auto', borderLeft: '1px solid black' }} className="ease bg-white">
                    <div className="d-flex flex-row p-3">
                        <i className="fas fa-times font-16 pointer" onClick={() => this.setState({ printing_roster: false })} />
                        <span className="black montserrat font-12 ml-auto">PRINT OPTIONS</span>
                        <i className="ml-auto" />
                    </div>
                    <div className="d-flex flex-column h-100 justify-content-center p-4">
                        <div onClick={this.download} style={{ height: 'auto', border: '1px solid gray' }} className="align-self-center w-100 bg-gray d-flex flex-column justify-content-center p-4 montserrat pointer hoverable">
                            <span className="fas fa-road font-24 black align-self-center" />
                            <span className="font-16 toBold black align-self-center">{this.state.downloading ? 'DOWNLOADING' : 'TRAVEL'}</span>
                            <span className="font-10 black line1 align-self-center">{this.state.downloading ? 'Please wait...' : 'Roster'}</span>
                        </div>
                        {!this.state.downloading && <div onClick={() => this.setState({ printing_roster: false, schedule: true, roster: false })} style={{ height: 'auto', border: '1px solid gray' }} className="mt-4 align-self-center w-100 bg-gray d-flex flex-column justify-content-center p-4 montserrat pointer hoverable">
                            <span className="fas fa-calendar font-24 black align-self-center" />
                            <span className="font-16 toBold black align-self-center">GAME DAY</span>
                            <span className="font-10 black line1 align-self-center">Roster</span>
                        </div>}
                    </div>
                </Animated>

                {/* ADD GUEST */}
                {this.state.adding_guests && <AddGuestPlayer limits={limits} team={season} games={this.props.games} network={this.props.network} rosterActions={this.props.rosterActions}
                    guests={_.filter(players, p => p.IdGuestPlayer)}
                    division={division}
                    players={_.filter(players, p => !p.IdGuestPlayer)}
                    onClose={() => this.setState({ adding_guests: false })}
                    onSelect={(player) => this.setState({ selectedPlayer: player })} />}

                {/* RED STRIPE, ILLEGAL ROSTERING */}
                {bootstrapped && !CanEditRoster_Allowed && CanEditRoster_Reason && <Animated animationIn="fadeIn" animationOut="fadeOutRight" style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                    <RedishHeader align="start" message={`${CanEditRoster_Reason}`} />
                </Animated>}

                {/* PLAYER DETAILS | SELECTED PLAYER */}
                {selectedPlayer && !selectedFamilyMember && <PlayerDetails {...this.props}
                    selectedTeam={{ ...selectedTeam, ...season }}
                    season={season}
                    entity={selectedPlayer}
                    isOpen={selectedPlayer ? true : false}
                    toggle={this.deselectPlayer}
                    permissions={this.props.permissions}
                    games={this.props.games}
                    onFamily={(member) => this.setState({ selectedPlayer: null, selectedFamilyMember: { ...member, IdTeamDivision: season.IdTeamDivision } })}
                    fnSuccess={this.update}
                    fnRefresh={this.refresh}
                    fnEvaluations={() => {
                        // There's a little logic here. If its the admin of the team, all good.
                        // If its a player, we need to check if there's at least one (1) evaluation where
                        // e.VISIBLE
                        if (season.IsTeamDivisionAdmin) {
                            this.setState({ selectedPlayer: null }, () => {
                                setTimeout(() => this.setState({ evaluations: selectedPlayer }), 300);
                            });
                        } else {
                            if (_.find(this.props.team_evaluations, e => (e.IdUserTarget || e.IdAssignedUser) === selectedPlayer.IdUser && e.VISIBLE)) {
                                this.setState({ selectedPlayer: null }, () => {
                                    setTimeout(() => this.setState({ evaluations: selectedPlayer }), 300);
                                });
                            }
                        }
                    }}
                />}

                {/* CREATE USER */}
                {selectedFamilyMember && <CreateUser preventDelete={true} {...this.props} toggle={() => this.setState({ selectedFamilyMember: null })} isOpen={selectedFamilyMember ? true : false} carriers={carriers}
                    user={selectedFamilyMember || {}} />}

                {/* JERSEYS */}
                {jerseing && <Jerseys {...this.props} season={season} roster={players} toggle={() => this.setState({ jerseing: false })} selectedTeam={{ ...selectedTeam, ...season }} />}

                {/* EVALUATING */}
                {evaluating && <TeamEvaluations {...this.props} scout_actions={this.props.teamActions} season={season} roster={_.filter(players, p => {
                    if (this.state.only_family) return p.IdFamily === fakeAuth.getUser().IdFamily;
                    else return true;
                })}
                    tabs={!this.state.only_family}
                    toggle={() => this.setState({ evaluating: false })} selectedTeam={{ ...selectedTeam, ...season }}
                    fnEvaluations={(player) => this.setState({ evaluating: null }, () => {
                        setTimeout(() => this.setState({ evaluations: player }), 300);
                    })} />}

                {/* HEARTBEAT */}
                <Animated animationOut="fadeOutRight" className={`bg-black d-flex flex-row justify-content-center w-100`} style={{ position: 'absolute', top: 0, bottom: 0 }} isVisible={!bootstrapped}>
                    <i className="heart white align-self-center icon-logo-header" style={{ fontSize: '50pt' }} onClick={this.show} />
                </Animated>

                {/* SIDE MENU SCHEDULE */}
                <Animated animationIn='slideInRight' animationOut="slideOutRight" animateOnMount={false} isVisible={(bootstrapped && schedule && games_tab === 2 && this.state.team && (this.state.team.IsTeamAdmin || this.state.team.IsTeamOwner)) ? true : false} style={{ position: 'fixed', top: 250, right: 15 }} className="d-flex flex-column" animationInDuration={200} animationOutDuration={200}>
                    <button onClick={() => this.setState({ adding_event: true })} className={`hoverable card shadow p-2 d-flex flex-column justify-content-center`} style={{ width: 100 }}>
                        <i className="fas fa-plus font-20 align-self-center" />
                        <span className="font-10 align-self-center">Add Event</span>
                    </button>
                </Animated>

                {/* ADD EVENT OPTIONS */}
                {this.state.adding_event && !this.state.entity && <div onClick={() => this.setState({ adding_event: null })} className="Overlay show" />}
                <Animated animationIn='slideInRight' animationOut="slideOutRight" animationInDuration={200} animationOutDuration={200} animateOnMount={false} isVisible={(this.state.adding_event && !this.state.entity) ? true : false} style={{ position: 'fixed', right: 0, top: 0, zIndex: 10000, bottom: 0, width: 400, overflowY: 'auto' }} className="d-flex flex-column bg-blue bg-dark-blue">
                    <h5 className="white monterrat text-center mt-4">ADD</h5>
                    {/* ADD GAME */}
                    <div onClick={() => this.setState({ adding_game: true })} className="card hoverable tile px-4 py-2 mx-4 mt-4 pointer">
                        <div className="d-flex flex-row">
                            <i className="fas fa-circle green font-12 align-self-center" />
                            <span className="montserrat font-20 black align-self-center ml-2">Game</span>
                        </div>
                        <p className="black montserrat line1 font-10">A structured form of play, usually undertaken for enjoyment and sometimes used as an educational tool</p>
                    </div>
                    {/* ADD PRACTICE */}
                    <div onClick={() => this.setState({ adding_practice: true })} className="card hoverable tile px-4 py-2 mx-4 mt-4 pointer">
                        <div className="d-flex flex-row">
                            <i className="fas fa-circle yellow font-12 align-self-center" />
                            <span className="montserrat font-20 black align-self-center ml-2">Training</span>
                        </div>
                        <p className="black montserrat line1 font-10">The act of rehearsing a behavior over and over, or engaging in an activity again and again, for the purpose of improving or mastering it</p>
                    </div>
                    {/* ADD EVENT */}
                    <div onClick={() => this.setState({ adding_another_event: true })} className="card hoverable tile px-4 py-2 mx-4 mt-4 pointer">
                        <div className="d-flex flex-row">
                            <i className="fas fa-circle purple font-12 align-self-center" />
                            <span className="montserrat font-20 black align-self-center ml-2">Event</span>
                        </div>
                        <p className="black montserrat line1 font-10">A gathering of individuals engaged in some common interest</p>
                    </div>
                </Animated>

                {this.state.adding_game && this.state.adding_event && <AddGame
                    toggle={() => this.setState({ adding_game: false })}
                    fnAdded={() => this.setState({ adding_game: false, adding_event: false }, () => {
                        this.props.teamActions.fetchSchedule(season.IdTeamDivision);
                        this.props.teamActions.fetchOtherEvents(season.IdTeamDivision);
                    })}
                    team={this.state.selectedTeam}
                    opponents={this.props.opponents}
                    locations={this.props.locations}
                    teamActions={this.props.teamActions}
                    idTeamDivision={this.state.season.IdTeamDivision}
                />}

                {this.state.adding_practice && this.state.adding_event && <AddPractice
                    toggle={() => this.setState({ adding_practice: false })}
                    fnAdded={() => this.setState({ adding_practice: false, adding_event: false, non_official_event: null }, () => {
                        this.props.teamActions.fetchSchedule(season.IdTeamDivision);
                        this.props.teamActions.fetchOtherEvents(season.IdTeamDivision);
                    })}
                    entity={this.state.entity}
                    team={this.state.selectedTeam}
                    locations={this.props.locations}
                    teamActions={this.props.teamActions}
                    idTeamDivision={this.state.season.IdTeamDivision}
                />}

                {this.state.adding_another_event && this.state.adding_event && <AddEvent
                    toggle={() => this.setState({ adding_another_event: false })}
                    fnAdded={() => this.setState({ adding_another_event: false, adding_event: false, non_official_event: null }, () => {
                        this.props.teamActions.fetchSchedule(season.IdTeamDivision);
                        this.props.teamActions.fetchOtherEvents(season.IdTeamDivision);
                    })}
                    entity={this.state.entity}
                    team={this.state.selectedTeam}
                    locations={this.props.locations}
                    teamActions={this.props.teamActions}
                    idTeamDivision={this.state.season.IdTeamDivision}
                />}

                {/* PREMIUM MODAL */}
                {this.state.upgrading && <TeamPremiumModal
                    {...this.props}
                    team={season}
                    toggle={() => this.setState({ upgrading: !this.state.upgrading })}
                    success={() => this.props.teamActions.fetchSubscriptions(this.props.params.idTeam)}
                    isOpen={this.state.upgrading}
                    target={_.find(this.props.atp, p => p.Amount)} />}

            </section>
        )
    }
}

export default RosterTeam;
