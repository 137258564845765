import React from 'react';
import { Input, Popover, PopoverBody } from 'reactstrap';
import config from '../../../../../../config';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../../../common/CustomDatePicker';
import moment from 'moment';

class DivisionListItem extends React.Component {

    state = {
        saving: false,
        deleting: false,
        microTransac: false,
        touched: false,
        addingCap: false,
        capDDOpen: false,
        addingDateRange: false,
        dateRangeOpen: false,
        changingStatus: false,
        visible: true
    }

    // Lifecycle
    componentDidMount() {
        const { division } = this.props;
        if (division.editing) {
            this.txtName.focus();
            this.txtName.select();
        }
    }

    componentWillReceiveProps = nextProps => {
        if (nextProps.signal !== this.props.signal) {
            const { division, index } = this.props;
            this.props.fnReady && this.props.fnReady({ division: { ...division, Name: (this.txtName || {}).value }, index, visible: this.state.visible })
        }
    }
    //    

    handleChangeGender = (e) => {
        const { division, index } = this.props
        division.IdGender = parseInt(e.target.value, 10);
        division.Gender = _.find(config.genders, (g) => { return g.IdGender === division.IdGender; }).Name
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
        this.forceUpdate();
    }

    // status handle
    toggleStatusChange = () => {
        this.setState({ changingStatus: !this.state.changingStatus });
    }
    // Date Range handle
    toggleAddDateRange = () => {
        this.setState({ addingDateRange: !this.state.addingDateRange });
    }

    setStatus = (id) => {
        const { division, index } = this.props, { divisionStatuses } = config;
        const newStatus = _.find(divisionStatuses, s => { return s.IdRoleStatus === id; });
        division.DivisionStatus = newStatus.RoleStatusName;
        division.IdDivisionStatus = newStatus.IdRoleStatus;

        // Update the store
        this.props.divisionsAction &&
            this.props.divisionsActions.updateDivision(division, index);
        this.toggleStatusChange();
    }

    handleChangeDateStart = (date) => {
        const { division, index } = this.props;
        division.DateBirthMin = moment(date).utc();
        this.props.divisionsAction &&
            this.props.divisionsActions.updateDivision(division, index);
        this.setState({ b: !this.state.b })
    }

    handleChangeDateEnd = (date) => {
        const { division, index } = this.props;
        division.DateBirthMax = moment(date).utc();
        this.props.divisionsAction &&
            this.props.divisionsActions.updateDivision(division, index);
        this.setState({ b: !this.state.b })
    }

    saveDateRange = () => {
        const { division, index } = this.props;
        division.DateBirthMax = division.DateBirthMax || moment().add(-1, 'years');
        division.DateBirthMin = division.DateBirthMin || moment().add(-5, 'years');
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
        this.setState({ addingDateRange: false });
    }

    deleteDateRange = () => {
        const { division, index } = this.props;
        division.DateBirthMax = null;
        division.DateBirthMin = null;
        this.props.divisionsActions &&
            this.props.divisionsActions.updateDivision(division, index);
        this.setState({ addingDateRange: false });
    }
    //

    renderEdit = () => {
        const { division, index } = this.props;

        const genders = config.genders;

        if (this.state.visible) {
            return <tr>
                <td>
                    <input placeholder="Division Name" className="form-control" type="text" defaultValue={division.Name || division.DivisionName} ref={(input) => this.txtName = input} />
                </td>
                <td className="text-center">
                    <Input type="select" name="seasonType" value={division.IdGender} onChange={this.handleChangeGender}>
                        {genders.map((gender, i) => (
                            <option value={gender.IdGender} key={i}>
                                {gender.Name}
                            </option>))}
                    </Input>
                </td>
                {/* Date Range */}
                <td className="text-center">
                    <button type='button' onClick={this.toggleAddDateRange} id={`btnAddDateRange-${division.IdDivision}-${index}`} className={`btn btn-link blue margin-left-half align-self-center underlined font-${division.DateBirthMin ? '8' : '12'}`}>{division.DateBirthMin ? `${moment(division.DateBirthMin).utc().format('MMM Do YYYY')} to ${moment(division.DateBirthMax).utc().format('MMM Do YYYY')}` : `Add Date Range`}</button>
                    <Popover placement="bottom" isOpen={this.state.addingDateRange} target={`btnAddDateRange-${division.IdDivision}-${index}`} toggle={this.toggleAddDateRange}>
                        <PopoverBody className="d-flex flex-column no-padding">
                            {/*<div className="p-3 bg-blue-dark white" style={{ width: 300 }}>Setup</div>*/}
                            <div className="d-flex bg-warning" style={{ width: 300 }}>
                                <span className="w-100 text-center font-10">Example: Jan 1st 1998 to Dec 31st 2000</span>
                            </div>
                            <div className="d-flex flex-row justify-content-center pt-2">
                                <span className="text-center w-100">Oldest</span><span>TO</span><span className="text-center w-100">Youngest</span>
                            </div>
                            <div className="d-flex flex-row justify-content-center">
                                <DatePicker className="align-self-center w-100" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={70} customInput={<CustomDatePicker className="w-100 align-self-center" />} selected={division.DateBirthMin ? moment(division.DateBirthMin).utc() : moment().add(-5, 'years')} maxDate={moment()} selectsStart
                                    startDate={division.DateBirthMin ? moment(division.DateBirthMin).utc() : moment().add(-5, 'years')} endDate={division.DateBirthMax ? moment(division.DateBirthMax).utc() : moment().add(-1, 'years')} onChange={this.handleChangeDateStart} />
                                <div style={{ width: 20 }} />
                                <DatePicker className="align-self-center w-100" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={70} customInput={<CustomDatePicker className="w-100 align-self-center" />} selected={division.DateBirthMax ? moment(division.DateBirthMax).utc() : moment().add(-1, 'years')} maxDate={moment()} selectsEnd
                                    startDate={division.DateBirthMin ? moment(division.DateBirthMin).utc() : moment().add(-5, 'years')} endDate={division.DateBirthMax ? moment(division.DateBirthMax).utc() : moment().add(-1, 'years')} onChange={this.handleChangeDateEnd} />
                            </div>
                            <div className="d-flex flex-row p-3">
                                <button style={{ border: '1px solid red', borderRadius: 20, width: 80 }} className="red p-2 align-self-center block" onClick={this.deleteDateRange}>Delete</button>
                                <button style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 80 }} className="green ml-auto p-2 align-self-center block" onClick={this.saveDateRange}>Save</button>
                            </div>
                        </PopoverBody>
                    </Popover>
                </td>

                {/* Status */}
                <td className="d-flex flex-row">

                    <button type='button' onClick={this.toggleStatusChange} id={`btnChangeStatus-${division.IdDivision}-${index}`}
                        className={`btn btn-link blue margin-left-half align-self-center underlined font-12`}>{division.DivisionStatus}</button>
                    <button onClick={() => this.setState({ visible: false })} className="ml-auto btn btn-link align-self-center"><i className="far fa-trash-alt" /></button>
                    <Popover placement="bottom" isOpen={this.state.changingStatus} target={`btnChangeStatus-${division.IdDivision}-${index}`}
                        toggle={this.toggleStatusChange}>
                        <PopoverBody className="d-flex flex-column">
                            <button className="btn btn-link btn-sm " onClick={() => this.setStatus(1)}>Open</button>
                            <button className="btn btn-link btn-sm " onClick={() => this.setStatus(2)}>Closed</button>
                            <button className="btn btn-link btn-sm " onClick={() => this.setStatus(3)}>Waitlisted</button>
                            <button className="btn btn-link btn-sm " onClick={() => this.setStatus(4)}>Tryout</button>
                        </PopoverBody>
                    </Popover>

                </td>
            </tr>
        } else {
            return null;
        }
    }

    render() {
        return this.renderEdit();
    }
}

export default DivisionListItem;
