import React from 'react';
import _ from 'lodash';
import DivisionListItem from './item';
import { Jumbotron } from 'reactstrap';

class DivisionsTable extends React.Component {

    state = {
        up: true,
        by: 'IdDivision'
    }

    // lifecycle

    //    

    sort = newBy => {
        const { up, by } = this.state;
        if (by === newBy) {
            this.setState({
                up: !up
            });
        } else {
            this.setState({
                up: false,
                by: newBy
            });
        }
    }

    add = () => {
        this.props.divisionsActions &&
            this.props.divisionsActions.addDivision({
                CapEnabled: true,
                Cap: 0,
                CapAction: '',
                editing: true,
                Gender: 'Male',
                TotalTeams: 0,
                Teams: [],
                IdGender: 1 // Male by default
            });
    }

    fnReady = ({ division, index, visible }) => {
        const { divisions } = this.props;
        divisions[index] = { ...division, ready: true, visible };
        if (!_.find(divisions, d => (!d.ready))) {
            this.props.fnSave && this.props.fnSave(divisions)
        }
        this.setState({ divisions: _.filter(divisions, d => d.visible) });
    }

    removeDivision = (index) => {
        var { divisions } = this.state;
        divisions = _.reject(divisions, (d) => { return d.IdDivision === index; });
        this.setState({ divisions: [...divisions] })
    }

    renderTable = () => {
        const { divisions } = this.props;
        return <table className="table table-sm" style={{ borderBottom: '1px solid white' }}>
            <thead>
                <tr className="black">
                    <th scope="col">Division</th>
                    <th scope="col" className="text-center">Gender</th>
                    <th scope="col" className="text-center">Date Range</th>
                    <th scope="col" className="text-left">Status</th>
                </tr>
            </thead>
            <tbody>
                {divisions && _.sortBy(divisions, d => (d.Name || d.DivisionName)).map((division, i) =>
                    <DivisionListItem fnReady={this.fnReady} {...this.props} division={division} index={i} key={i} fnRemove={() => this.removeDivision(division.IdDivision)} />)}
                {divisions && !divisions.length && <tr>
                    <td colSpan={4}><Jumbotron><h1>This template has no divisions created yet!</h1></Jumbotron>
                    </td></tr>}
            </tbody>
        </table>
    }

    render() {
        return this.renderTable();
    }
}

export default DivisionsTable;
