import _ from 'lodash';
import moment from 'moment';
import config from './config';
import Crypto from 'crypto-js';

const precision = 1, { currencies } = config;

export function countCertainDays(days, d0, d1) {
    let ndays = moment(d1).diff(moment(d0), 'Days') + 1;
    return _.reduce(days, (a, b) => a + Math.floor((ndays + (d0.day() + 6 - b) % 7) / 7), 0);
}

export function formatPrice(cents) {
    let currency = currencies[localStorage.getItem('sqwad.currency_code') || 'USD'];
    return `${currency.symbol}${(cents / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function isNYDST(){
    // Get the current date in UTC
    const now = new Date();

    // Get the current date and time in New York
    const currentDate = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));

    // Determine DST start and end dates for New York for the current year
    const year = currentDate.getFullYear();
    const dstStart = new Date(Date.UTC(year, 2, 8 - ((new Date(Date.UTC(year, 2, 1)).getUTCDay() + 7 - 0) % 7), 2, 0, 0, 0));
    const dstEnd = new Date(Date.UTC(year, 10, 1 - ((new Date(Date.UTC(year, 10, 1)).getUTCDay() + 7 - 0) % 7), 2, 0, 0, 0));

    // Adjust for local time zone
    dstStart.setUTCMinutes(dstStart.getUTCMinutes() - dstStart.getTimezoneOffset());
    dstEnd.setUTCMinutes(dstEnd.getUTCMinutes() - dstEnd.getTimezoneOffset());

    // Check if current date is within DST period
    return currentDate >= dstStart && currentDate < dstEnd;
}

export function formatPriceWithoutCurrency(cents) {
    return `${(cents / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function rando(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}

export function getMaskedValue(v, mask) {
    let i = 0;
    return mask.replace(/_/g, l => (v.toString()[i++] || '_'));
}

export function validateEmail(email) {

    // Define a regular expression pattern for a valid email address
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Use the test method to check if the email matches the pattern
    return emailPattern.test(email);

    /*const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());*/
}

export function parseMilliseconds(milliseconds) {

    //Get hours from milliseconds
    var hours = milliseconds / (1000 * 60 * 60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

    //Get remainder from minutes and convert to seconds
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

    return h + ':' + m + ':' + s;
}

export function getFunName() {
    const adjectives = ['adorable', 'beautiful', 'clean', 'drab', 'elegant', 'fancy', 'glamorous', 'handsome', 'long', 'magnificent', 'old-fashioned', 'plain', 'quaint', 'sparkling', 'ugliest', 'unsightly', 'angry', 'bewildered', 'clumsy', 'defeated', 'embarrassed', 'fierce', 'grumpy', 'helpless', 'itchy', 'jealous', 'lazy', 'mysterious', 'nervous', 'obnoxious', 'panicky', 'repulsive', 'scary', 'thoughtless', 'uptight', 'worried'];
    const nouns = ['women', 'men', 'children', 'teeth', 'feet', 'people', 'leaves', 'mice', 'geese', 'halves', 'knives', 'wives', 'lives', 'elves', 'loaves', 'potatoes', 'tomatoes', 'cacti', 'foci', 'fungi', 'nuclei', 'syllabuses', 'analyses', 'diagnoses', 'oases', 'theses', 'crises', 'phenomena', 'criteria', 'data'];
    return `${rando(adjectives)}-${rando(adjectives)}-${rando(nouns)}`;
}

export function calculateBWContrast(hex, bw) {

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

    var o = Math.round(((r * 299) +
        (g * 587) +
        (b * 114)) / 1000);

    return o > 125
        ? '#000000'
        : '#FFFFFF';
}

export function LightenDarkenColor(col, amt) {


    if (col[0] === "#") {
        col = col.slice(1);
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;
    var b = (num & 0x0000FF) + amt;
    var g = ((num >> 8) & 0x00FF) + amt;


    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    // convert 3-digit hex to 6-digits.
    let hex = (g | (b << 8) | (r << 16)).toString(16);
    if (hex.length === 1) {
        hex = hex[0] + hex[0] + hex[0] + hex[0] + hex[0] + hex[0];
    }
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    return `rgb(${r},${g},${b})`;
    //return (usePound ? "#" : "") + hex;
}

export function calculateBWContrastClass(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 1) {
        hex = hex[0] + hex[0] + hex[0] + hex[0] + hex[0] + hex[0];
    }
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        return 'black';
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

    var o = Math.round(((r * 299) +
        (g * 587) +
        (b * 114)) / 1000);

    return o > 125
        ? 'black'
        : 'white';
}

export const ItemTypes = {
    CUSTOMGROUP: 'customgroup',
    DIVISION: 'division',
    TEAM: 'team',
    FLIGHT: 'flight',
    FLIGHTEDTEAMS: 'flightedTeam',
    GAMEDAYRULE: 'gamedayrule',
    EXCEPTIONRULE: 'exceptionrule',
    FIELDAVAILABILITYRULE: 'fieldavailabilityrule',
    BRACKETPLACEMENT: 'bracketplacement',
    ROSTEREDPLAYER: 'rosteredplayer',
    NONROSTEREDPLAYER: 'nonrosteredplayer',
    REGISTRATIONFEE: 'registrationfee',
    TRYOUT: 'tryout',
    ADJUSTMENT: 'adjustment',
    ZIPCODE: 'zipcode',
    FAMILYADJUSTMENT: 'family_adjustment',
    FREETEXT: '2',
    SINGLESELECTION: 'single_selection',
    MULTIPLESELECTION: 'multiple_selection',
    RADIO: '6',
    CHECK: '3',
    CHECKLIST: '4',
    SELECT: '5',
    QANDA: 'q_and_a',
    QUESTIONGROUP: 'question_group',
    ROSTERLOCK: 'roster_lock',
    APPLIEDQUESTION: 'applied_question'
};

export function generateRandomId(x) {
    const data = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'A', 'B', 'C', 'D', 'E', 'F'];
    return ("ID_" + _.sampleSize(data, x).join(''));
}

export function generateRandomString(x) {
    const data = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'O', 'P', 'R', 'S', 'T', 'X', 'Y', 'Z'];
    return (_.sampleSize(data, x).join(''));
}

export function detectCreditCardType(number) {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null)
        return {
            IdPaymentMethod: 10,
            Name: "Visa",
            Image: "/images/icon/visa-white.png"
        };

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
        return {
            IdPaymentMethod: 20,
            Name: "Mastercard",
            Image: "/images/icon/mastercard-white.png"
        };

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return {
            IdPaymentMethod: 30,
            Name: "Amex",
            Image: "/images/icon/card-white.png"
        };

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return {
            IdPaymentMethod: 40,
            Name: "Discover",
            Image: "/images/icon/card-white.png"
        };

    return {
        Image: "/images/icon/card-white.png"
    };
}

export function toTitleCase(str) {

    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );

}

export function getOrdinal(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function getReadableFileSizeString(fileSizeInBytes) {
    if (!fileSizeInBytes) return '0KB';
    var i = -1;
    var byteUnits = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export function convert(val) {
    return Math.round(val * precision);
}

export function toSeconds(val) {
    if (val === 0) {
        return 0;
    }

    return val / precision;
}

export function format(val) {
    return toSeconds(val).toFixed(2);
}

export function log(args) {
    _.keys(args).forEach(key => {
        console.log('')
        console.log('')
        console.log(`------- ${key.toUpperCase().split('').join(' ')} -------`)
        console.log(args[key])
        console.log('-------')
        console.log('')
        console.log('')
    });
}

export function getCurrentWeek() {
    let currentDate = moment();
    let weekStart = currentDate.clone().startOf('isoWeek');
    let weekEnd = currentDate.clone().endOf('isoWeek');
    return [weekStart.format('YYYY-MM-DD'), weekEnd.format('YYYY-MM-DD')];
}

export function getCurrentMonth() {
    let first = moment(`${moment().format('YYYY')}-${moment().format('MM')}-01`, 'YYYY-MM-DD'),
        last = moment(`${moment().add(1, 'month').format('YYYY')}-${moment().add(1, 'month').format('MM')}-01`, 'YYYY-MM-DD').add(-1, 'days');
    return [first.format('YYYY-MM-DD'), last.format('YYYY-MM-DD')];
}

const keys = ['NameFirst', 'NameLast', 'UserName', 'UserImage', 'DateBirth', 'Name', 'Image', 'ImageUrl',
    'PhoneNumber_Format', 'PhoneNumber', 'CarrierName', 'EmailAddress', 'Line1', 'City', 'FullPhoneNumber',
    'State', 'Country', 'Address', 'TeamName', 'UserImageUrl', 'FamilyLastName', 'Organizer_EmailAddress',
    'Organizer_Name', 'Confirmation', 'GatewayName', 'DocumentNumber', 'number', 'name', 'cvv', 'expDate', 'expdate', 'zip'];

export function humanize_user(user) {
    let offset = parseInt(moment().utc().format('D'));
    keys.forEach(k => {
        // TODO: i'm not sure about the SALT issue
        try { user[k] = Crypto.TripleDES.decrypt(user[k], `secret-${offset}-secret`).toString(Crypto.enc.Utf8); }
        catch (e) { }
    });
}

export function encrypt_user(user) {
    let offset = parseInt(moment().utc().format('D'));
    keys.forEach(k => {
        // TODO: i'm not sure about the SALT issue
        try { if (user[k]) user[k] = Crypto.TripleDES.encrypt(user[k], `secret-${offset}-secret`).toString(); }
        catch (e) { }
    });
}

export function mask_phone_number({ entry,
    mask = /([^\d]*\d[^\d]*){0,10}$/,
    reverse = '(__) ___-____' }) {

    const match = entry
        .replace(/\D+/g, '') // removes any non-number (+...)
        //.replace(/^1/, '') // removes the start 1
        .match(mask)[0]; // process regex
    let i = 0;
    return reverse.replace(/_/g, l => (match[i++] || '_'));
}

export function isDST() {
    // Get the current date
    const currentDate = new Date();

    // Get the standard time offset for January (assuming no DST in January)
    const january = new Date(currentDate.getFullYear(), 0, 1);
    const standardOffset = january.getTimezoneOffset();

    // Get the current time offset
    const currentOffset = currentDate.getTimezoneOffset();

    // If the current time offset is not equal to the standard offset, DST is in effect
    return currentOffset !== standardOffset;
}
